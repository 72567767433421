<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import Axios from "@/plugins/axios";
import UserTableCard from "@/components/dashboard/user/user-table-card.vue";
import DataLoader from "@/components/data-loader.vue";
import UserFormModal from "@/components/dashboard/user/user-form-modal.vue";
import Pagination from "@/components/dashboard/Pagination.vue";
import {ADMIN_} from "@/helpers/constant/roles";
import {exportToExcel} from "@/service/export.service";
import Spinner from "@/components/spinner.vue";

export default {
  page: {
    title: "Adhérents",
    meta: [{ name: "description", content: 'User Management' }],
  },
  components: {
    Spinner,
    Pagination,
    UserFormModal,
    DataLoader,
    UserTableCard,
    Layout,
    PageHeader,
  },
  data() {
    return {
      users: [],
      showDataLoader: true,
      userFormModal: false,
      activeUser: null,
      next: null,
      previous: null,
      page: 1,
      totalItems: 0,
      query: '',
      url: '',
      isExportSubmitted: false,
      userRole: 'user'
    }
  },
  mounted() {
    this.initUrl()
    this.getUsers()
  },
  methods: {
    ADMIN_() {
      return ADMIN_
    },
    openAddUserModal(){
      this.userFormModal = true
    },
    openUpdateUserModal(user){
      this.activeUser = user
      this.userFormModal = true
    },
    closeModal(type){
      switch (type) {
        case 'user-form':
          this.activeUser = null
          this.userFormModal = false
          break
        case 'user-update-form':
          this.activeUser = null
          this.userFormModal = false
          break
      }
    },
    updateUserTable(user){
      this.users = this.users.filter(u => u.id !== user.id)
      this.users.unshift(user)
    },
    getUsers(){
      Axios.get(this.url)
          .then(response => {
            this.setPagination(response)
            response.data["hydra:member"].map(user => {
              this.users.push(user)
            })

            this.showDataLoader = false
          })
          .catch(error => {
            console.log(error)
          })
    },
    initUrl(){
      this.url = '/users/get/by_roles?role=' + this.userRole + '&page=' + this.page + '&query=' + this.query
    },
    setPagination(response){
      if (response.data["hydra:view"]["hydra:next"]) {
        this.totalItems = response.data["hydra:totalItems"]
        this.next = response.data["hydra:view"]["hydra:next"]
        this.previous = response.data["hydra:view"]["hydra:previous"]
      }
    },
    nextPage(){
      this.setCurrentPage(this.next)
      this.users = []
      this.getUsers()
    },
    previousPage(){
      this.setCurrentPage(this.previous)
      this.users = []
      this.getUsers()
    },
    navigate(page){
      this.page = page
      this.users = []
      this.showDataLoader = true
      this.initUrl()
      this.getUsers()
    },
    setCurrentPage(url){
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url.split('api/')[1]
      this.showDataLoader = true
    },
    searchUser(searchBody){
      const {query} = searchBody
      this.query = query
      this.showDataLoader = true
      this.users = []
      this.initUrl()
      this.getUsers()
    },
    exportUsersToExcel() {
      this.isExportSubmitted = true
      exportToExcel('/export-users-by-role?role=' + this.userRole)
          .finally(() => this.isExportSubmitted = false)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Adhérents'"
        :button-title="'+ Adhérents'"
        @clickButton="openAddUserModal"
        @submitSearch="searchUser"
    />
    <!--    <Notification/>-->
    <UserFormModal
        @onModalClose="closeModal"
        @userCreated="updateUserTable"
        :activeUser="activeUser"
        :title="'Créer Adhérent'"
        v-model="userFormModal"
    />
    <DataLoader v-if="showDataLoader"/>
    <section>
      <div class="" v-if="users.length > 0 && !showDataLoader">
        <div class="card">
          <div class="card-header border-bottom-dashed">
            <div class="row g-4 align-items-center">
              <div class="col-sm">
              </div>
              <div class="col-sm-auto">
                <div class="d-flex flex-wrap align-items-start gap-2">
                  <button :disabled="isExportSubmitted" @click="exportUsersToExcel" class="btn btn-md btn-info" title="exporter vers un fichier excel">
                    <span v-if="!isExportSubmitted">
                      <i class=" ri-download-2-line align-bottom me-1"></i>
                      Excel
                    </span>
                    <spinner v-if="isExportSubmitted"/>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <UserTableCard
                :users="users"
                :page-name="'members'"
                @openUpdateUserModal="openUpdateUserModal"
                :role="ADMIN_()"
            />
            <Pagination
                :next="next"
                :previous="previous"
                :currentPage="page"
                :totalItems="totalItems"
                @navigate="navigate"
                @nextPage="nextPage"
                @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>